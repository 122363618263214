import React, { useState, useEffect, createContext } from 'react';

export const subscribeContext = createContext();

const SubscribeProvider = (props) => {
	const { Provider } = subscribeContext;
	const [active, setActive] = useState(false)
	const [defaultList, setDefaultList] = useState(false)
	const [useSocialField, setUseSocialField] = useState(false)
	const [disableClose, setDisableClose] = useState(false)

	return (
		<Provider
			value={{
				active,
				setActive,

				defaultList,
				setDefaultList,
				
				useSocialField,
				setUseSocialField,

				disableClose,
				setDisableClose
			}}
		>
            {props.children}
		</Provider>
	)
}

export default SubscribeProvider
