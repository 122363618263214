import { css } from 'styled-components'
import { media } from './utils'

const type = {
    heading1: css`
        font-size: 134px;
        line-height: 0.82;
        text-transform: uppercase;
        
        ${media.tablet`
            font-size: 50px;
        `}
    `,
    heading2: css`
        font-size: 80px;
        line-height: 0.82;
        text-transform: uppercase;

        ${media.tablet`
            font-size: 50px;
        `}
    `,
    heading3: css`
        font-size: 36px;
        text-transform: uppercase;
        line-height: 0.92em;

        ${media.tablet`
            font-size: 24px;
        `}
    `,
    heading4: css`
		font-size: 24px;
        text-transform: uppercase;
        line-height: 0.92em;

        ${media.phone`
            font-size: 16px;
        `}
    `,
    heading5: css`
        font-size: 16px;
        text-transform: uppercase;
        line-height: 0.94em;
    `,
    body: css`
        font-family: 'HelveticaNeue';
        font-size: 16px;
        line-height: 1.38em;
    `,
}

export default type