import React from 'react'
import Transition from "./src/transition"
import { GlobalStyles } from './src/styles/global';
import SubscribeProvider from './src/components/Subscribe/SubscribeProvider';
import { PortalProvider } from "react-portal-hook";

export default ({ element, props  }) => {
    return (
        <>
            <SubscribeProvider>
                <PortalProvider>
                    <GlobalStyles/>
                    {element}
                </PortalProvider>
            </SubscribeProvider>
        </>
    )
}