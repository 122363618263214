import { css, createGlobalStyle } from 'styled-components';
import globalType from './type'
import globalColours from './colours';
import { media } from './utils';

export const maxWidth = 1680;

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: black;
        color: white;
        font-family: 'FavoritStd';
        -webkit-font-smoothing: antialiased;
    }
    
    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p, div {
        color: white;
    }

    p {
        margin-bottom: 26px;

        a {
            background-size: 1px 1em;
            box-shadow:
                inset 0 -0.175em black,
                inset 0 -0.22em ${globalColours.lemon};
        }
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
    }

    p:first-child {
		margin-top: 0;
    }
    
    #gatsby-focus-wrapper {
        width: 100%;
    }
`;

export const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
    width: 100%;
`


export const container = css`
    display: flex;
	width: 100%;
    max-width: ${maxWidth}px;
`

export const hoverState = css`
    transition: 0.15s opacity ease;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 30px; 
    padding-right: 30px;
    box-sizing: border-box;

    ${media.phone`
        padding-left: 15px; 
        padding-right: 15px;
    `}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const pagePaddingTop = css`
    padding-top: 240px;

    ${media.tablet`
        padding-top: 120px;
    `}

    ${media.phone`
        padding-top: 86px;
    `}
`


// Re-export colours & type

export const colours = globalColours
export const type = globalType