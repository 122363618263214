// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-connect-js": () => import("./../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-hello-js": () => import("./../src/pages/hello.js" /* webpackChunkName: "component---src-pages-hello-js" */),
  "component---src-pages-home-hero-js": () => import("./../src/pages/home/Hero.js" /* webpackChunkName: "component---src-pages-home-hero-js" */),
  "component---src-pages-home-index-js": () => import("./../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-intro-js": () => import("./../src/pages/home/Intro.js" /* webpackChunkName: "component---src-pages-home-intro-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-live-js": () => import("./../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-speaking-js": () => import("./../src/pages/speaking.js" /* webpackChunkName: "component---src-pages-speaking-js" */),
  "component---src-pages-subscribe-js": () => import("./../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */)
}

